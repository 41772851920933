import { Await, useLocation, useParams} from 'react-router-dom';

import './ItemDetails.css';
import React, { useState, useEffect } from 'react';
import algoliasearch from 'algoliasearch';




const searchClient = algoliasearch(
  'MEUZ4222NX',
  'c65210b692a8704af80299c06595d55e'
);

// const indexName="master_biblio_join_filter_clean_regex_lastyear"
const indexName="prod"


const fetchHitByObjectID = async (objectID) => {
  try {
    const index = searchClient.initIndex(indexName);
    // console.log("fetchHitByObjectID", objectID, indexName);
    // console.log(typeof index);
    // console.log(index);
    // console.log(Object.getOwnPropertyNames(index));
    const response = await index.getObject(objectID);
    return response;
  } catch (error) {
    console.error('Error fetching hit:', error);
    return null;
  }
};


function ItemDetails() {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(currentURL)
      .then(() => {
        // Successfully copied to clipboard!
        alert('Link copied to clipboard!');
      })
      .catch(err => {
        // Unable to copy to clipboard
        console.error('Failed to copy:', err);
      });
  };

  const convertCamelCaseToReadable = (str) => {
    return str
      .replace(/([A-Z])/g, ' $1')
      .replace(/I D/g, 'ID') // Exclude space before "ID" at word boundaries
      .replace(/U R L/g, 'URL') // Exclude space before "URL" at word boundaries
      .replace(/^./, (s) => s.toUpperCase())
      .replace(/Eebo/g, 'EEBO') // Make EEBO all uppercase
      .replace(/Estc/g, 'ESTC') // Make ESTC all uppercase
      .replace(/Ustc/g, 'USTC') // Make USTC all uppercase
      .replace(/Tcp/g, 'TCP') // Make TCP all uppercase
      .replace(/Marc/g, 'MARC') // Make MARC all uppercase
      .replace(/Auto Extracted/g, '(P&P auto extracted)') // Make P&P auto extracted all lowercase
      .trim();
  };


    const { objectID } = useParams();
    // console.log("foo")
    // let hit = await fetchHitByObjectID(objectID); 
    // console.log("fetched hit from fetchHitByObjectID")
    // console.log(objectID);
    // console.log(hit);
    const location = useLocation();
    const hit = location.state?.hit;

    // console.log("ItemDetails");
    if (!hit) {
        return <p>No hit details found</p>;
    }

    // Fetch the hit details based on the productID and display them
    const [currentURL, setCurrentURL] = useState('');

    useEffect(() => {
      // setCurrentURL(window.location.href);
      window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
    }, []);

    // Function to convert newlines to <br> tags
    const nl2br = (text) => {
      // console.log("nl2br", typeof text, text);
      if (typeof text !== 'string') {
        return text; // Return as is if not a string
      }
      return text.split('\n').map((line, index) => {
        return <React.Fragment key={index}>{line}<br /></React.Fragment>;
      });
    };

    const renderTableRows = () => {
      const excludedKeys = ['objectID', '_highlightResult', '__position', '__queryID'];
      const filteredHit = Object.fromEntries(
      Object.entries(hit).filter(([key, value]) => !excludedKeys.includes(key) && value !== '')
      );

      const sortedKeys = Object.keys(filteredHit).sort(); // Sort keys alphabetically

      // Move keys starting with "Holdings" to the end of the sortedKeys array
      const holdingsKeys = sortedKeys.filter(key => key.startsWith('Holdings'));
      const nonHoldingsKeys = sortedKeys.filter(key => !key.startsWith('Holdings'));
      const finalSortedKeys = [...nonHoldingsKeys, ...holdingsKeys];

      return finalSortedKeys.map((key) => (
      <tr key={key}>
        <td>{convertCamelCaseToReadable(key)}</td>
        {/* <td>{filteredHit[key]}</td> */}
        {/* Display line breaks from filteredHit[key] */}
        <td>{typeof filteredHit[key] === 'string' && filteredHit[key].startsWith('http') ? <a href={filteredHit[key]} target='_blank'>{filteredHit[key]}</a> : nl2br(filteredHit[key])}</td>
      </tr>
      ));
    };
  
  
    return (
      <div>
        <h2>Record Details  </h2>
        {/* <button onClick={copyToClipboard} className='button2'>Copy record link to clipboard</button>  */}
        {/* <p>Current Page URL: {currentURL}</p> */}
        {/* <p>Object ID: {objectID}</p> */}
        <table>
          <thead>
            <tr>
              <th>Attribute</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {renderTableRows()}
          </tbody>
        </table>   
      </div>
    );
  }

export default ItemDetails;
