import React, { useState, useRef, useEffect } from 'react';
import algoliasearch from 'algoliasearch/lite';
import {
  Configure,
  DynamicWidgets,
  Highlight,
  Hits,
  InstantSearch,
  Pagination,
  RefinementList,
  SearchBox,
  RangeInput,
  ClearRefinements,
} from 'react-instantsearch';
import { useNavigate } from 'react-router-dom';

import { Panel } from './Panel';
import { FacetDropdown } from './components/FacetDropdown';

import type { Hit } from 'instantsearch.js';
import qs from 'qs';
import { history } from 'instantsearch.js/es/lib/routers';


import './App.css';

const DEBOUNCE_TIME = 400;
const searchClient = algoliasearch(
  'MEUZ4222NX',
  'c65210b692a8704af80299c06595d55e'
);

const closeOnChange = () => window.innerWidth > 375;


const future = { preserveSharedStateOnUnmount: true };

const createURL = state => `?${qs.stringify(state)}`;

const searchStateToUrl = searchState =>
  searchState ? createURL(searchState) : '';

const urlToSearchState = ({ search }) => qs.parse(search.slice(1));



// const routing = {
//   router: history(),
// };

export function App() {
// export function App({ location, history}) {
  // console.log(location);
  // const [searchState, setSearchState] = useState(urlToSearchState(location));
  // const debouncedSetStateRef = useRef(null);

  // function onSearchStateChange(updatedSearchState) {
  //   clearTimeout(debouncedSetStateRef.current);

  //   debouncedSetStateRef.current = setTimeout(() => {
  //     history.push(searchStateToUrl(updatedSearchState));
  //   }, DEBOUNCE_TIME);

  //   setSearchState(updatedSearchState);
  // }

  // useEffect(() => {
  //   setSearchState(urlToSearchState(location));
  // }, [location]);

// ///////////////////////////////////////////////////////////

  // const [showAcknowledgment, setShowAcknowledgment] = useState(true);

  // const handleDismiss = () => {
  //   setShowAcknowledgment(false);
  // };
  
  return (
    <div>
      <header className="header">
      <h1 className="header-title">
        <a href="/">print & probability</a>
      </h1>
      <p className="header-subtitle">
        English Short Title Catalogue (ESTC) Search
      </p>
      <img src="pnp.png" alt="P & P logo" className="header-image"/>
      </header>

      <p className="header-desc">
          An independent, temporary search interface for the <a href="http://estc.bl.uk">English Short Title Catalogue</a> ordinarily hosted by The British Library (<a href="http://www.bl.uk/bibliographic/datafree.html">usage license</a>).
          <br />
          Created by <a href="https://nvog.github.io">Nikolai Vogler</a> for <a href="http://printprobability.org">Print & Probability</a>, an NEH- and NSF-funded project at UCSD and CMU.
          <br />
      </p>
      { (
        <div className="acknowledgment-box">
          <p className='header-acknowledgment'>
            <strong>About the data</strong>
            {/* <button className="dismiss-button" onClick={handleDismiss}>
              <i className="fas fa-times"></i>
            </button> */}
            <p>
              <em><strong>Update (Jun 12, 2024):</strong> Now with more data and Internet Archive links (where available)!</em>
            </p>
            <p>
              The ESTC is co-managed by the British Library (BL) and the UC Riverside's Center for Bibliographical Studies and Research (CBSR). The unofficial metadata provided here is based on goodwill.  Please be aware that it was not originally curated for the purposes of sharing publicly and that we are only doing so now to assist researchers impeded by the <a href="https://blogs.bl.uk/living-knowledge/2023/11/cyber-incident.html">cyberattack</a> on the British Library.  
            </p>
            <p>
              We note that some of the unofficial <em>pre-1700 metadata</em> served here may differ slightly from the metadata served by the British Library.  This pre-1700 metadata is based primarily on a snapshot of the ESTC taken circa 2014, when the snapshot of 136,735 records was shared with participants in the Folger Shakespeare Library's "Early Modern Digital Agendas" Summer Seminar.  As part of the <a href="http://printprobability.org">Print & Probability</a> project, Chris Warren and his research assistants at Carnegie Mellon made small modifications and transformations in the metadata for the purposes of asking and answering global questions about the number of anonymously-printed books and pamphlets. Additionally, most of the ESTC records have also been further enriched with <a href="https://github.com/Early-Modern-OCR/ImprintDB">metadata</a> derived from the <a href="http://early-modern-ocr.github.io">Early Modern OCR Project (EMOP)</a> at Texas A&M and <a href="https://quod.lib.umich.edu/e/eebogroup/">Early English Books Online (EEBO)</a>. The EMOP and EEBO datasets included 115,777 and 146,194 records respectively.  Since it has not always been possible to reconcile all of these datasets, the number of records included here is slightly larger, roughly 150,000 records.   
              We also acknowledge UC Riverside's <a href="https://cbsrinfo.ucr.edu/ESTC">CBSR</a> along with <a href="http://early-modern-ocr.github.io">EMOP</a> and <a href="https://quod.lib.umich.edu/e/eebogroup/">EEBO</a> as contributors.
            </p>
              Please note that your mileage may vary. We recommend you confirm any metadata found here with the BL's official ESTC when it comes online. Contact the ESTC with any questions/comments at <a href="mailto:estc@bl.uk">estc@bl.uk</a>.
          </p>
        </div>
      )}



<div className="container">
        <InstantSearch
          searchClient={searchClient}
          indexName="prod"
          future={future}
          insights={true}
          // searchState={searchState}
          // onSearchStateChange={onSearchStateChange}
          // createURL={createURL}
          // routing={routing}
          routing={true}
        >
          <Configure hitsPerPage={8} />

          <div className="search-panel">
            <SearchBox placeholder="Search over 480,000 ESTC records..." className="searchbox" />
            <div className="search-panel__filters">
              <FacetDropdown
                  closeOnChange={closeOnChange}
                  buttonText={({ refinements }) => {
                    const [start, end] = refinements;
                    return start || end
                      ? `Year (${(start || end).label}${
                          end ? ' - ' + end.label : ''
                        })`
                      : `Year`;
                  }}
                >
                  <RangeInput attribute="yearAutoExtracted" />
              </FacetDropdown>

              <FacetDropdown
                // buttonText="Printer"
                buttonText={({ refinements }) => {
                  console.log(refinements)

                  // console.log(typeof refinements);
                  const names = refinements;
                  // console.log(typeof names, names);
                  // console.log(Object.values(names))
                  // names is type CurrentRefinementsConnectorParamsRefinement
                  return names.length > 0 ? `Printer (${names.map((name) => name.label).join(', ')})` : `Printer`;
                }}
                closeOnChange={closeOnChange}
                classNames={{ root: 'my-SearchableDropdown' }}
              >
                <RefinementList
                  attribute="printedByAutoExtracted"
                  searchable={true}
                  searchablePlaceholder="Search for printers (auto-extracted)..."
                />
              </FacetDropdown>

              <FacetDropdown
                // buttonText="Publisher"
                buttonText={({ refinements }) => {
                  const names = refinements;
                  return names.length > 0 ? `Publisher (${names.map((name) => name.label).join(', ')})` : `Publisher`;
                }}
                closeOnChange={closeOnChange}
                classNames={{ root: 'my-SearchableDropdown' }}
              >
                <RefinementList
                  attribute="printedForAutoExtracted"
                  searchable={true}
                  searchablePlaceholder="Search for publishers (auto-extracted)..."
                />
              </FacetDropdown>

              <FacetDropdown 
                // buttonText="City"
                buttonText={({ refinements }) => {
                  const names = refinements;
                  return names.length > 0 ? `City (${names.map((name) => name.label).join(', ')})` : `City`;
                }}
                closeOnChange={closeOnChange}>
                <RefinementList attribute="city" />
              </FacetDropdown>

              <FacetDropdown 
                // buttonText="Genre"
                buttonText={({ refinements }) => {
                  const names = refinements;
                  return names.length > 0 ? `Genre (${names.map((name) => name.label).join(', ')})` : `Genre`;
                }}
                closeOnChange={closeOnChange}>
                <RefinementList attribute="genre" />
              </FacetDropdown>

              <FacetDropdown 
                // buttonText="Topical Term"
                buttonText={({ refinements }) => {
                  const names = refinements;
                  return names.length > 0 ? `Topical Term (${names.map((name) => name.label).join(', ')})` : `Topical Term`;
                }}
                closeOnChange={closeOnChange}>
                <RefinementList attribute="topicalTerm" />
              </FacetDropdown>

              <FacetDropdown 
                // buttonText="Specific Topical Term"
                buttonText={({ refinements }) => {
                  const names = refinements;
                  return names.length > 0 ? `Specific Topical Term (${names.map((name) => name.label).join(', ')})` : `Specific Topical Term`;
                }}
                closeOnChange={closeOnChange}>
                <RefinementList attribute="specificTopicalTerm" />
              </FacetDropdown>

              <FacetDropdown 
                // buttonText="Format"
                buttonText={({ refinements }) => {
                  const names = refinements;
                  return names.length > 0 ? `Format (${names.map((name) => name.label).join(', ')})` : `Format`;
                }}
                closeOnChange={closeOnChange}>
                <RefinementList attribute="format" />
              </FacetDropdown>

              {/* <div className="clear-refinements"> */}
                <ClearRefinements 
                  translations={{
                    resetButtonText: 'Clear filters',
                  }}
                />
              {/* </div> */}
            </div>


            
            <div className="search-panel__results">
              <Hits hitComponent={Hit} />

            </div>
            <Pagination className="search-panel__pagination" />
          </div>

        </InstantSearch>
      </div>
  </div>


    //   <div className="container">
    //     <InstantSearch
    //       searchClient={searchClient}
    //       indexName="master_biblio_join_filter_clean_full_holdings"
    //       future={future}
    //       insights={true}
    //     >
    //       <Configure hitsPerPage={8} />
    //       <div className="search-panel">
    //         <div className="search-panel__filters">
    //           <DynamicWidgets fallback={RefinementList}>
    //             {/* <Panel header="Year">
    //               <RangeInput attribute="yearAutoExtracted" />
    //             </Panel> */}

    //             <FacetDropdown
    //               closeOnChange={closeOnChange}
    //               buttonText={({ refinements }) => {
    //                 const [start, end] = refinements;
    //                 return start || end
    //                   ? `Price (${(start || end).label}${
    //                       end ? ' & ' + end.label : ''
    //                     })`
    //                   : `Price`;
    //               }}
    //             >
    //               <RangeInput attribute="yearAutoExtracted" />
    //             </FacetDropdown>

    //             <Panel header="Printer">
    //               <RefinementList 
    //               attribute="printedByAutoExtracted" 
    //               searchable
    //               />
    //             </Panel>
    //             <Panel header="Publisher">
    //               <RefinementList 
    //               attribute="publisher" 
    //               searchable
    //               />
    //             </Panel>
    //             <Panel header="City">
    //               <RefinementList attribute="city" />
    //             </Panel>
    //             <Panel header="Genre">
    //               <RefinementList attribute="genre" />
    //             </Panel>
    //             <Panel header="Topical Term">
    //               <RefinementList attribute="topicalTerm" />
    //             </Panel>
    //             <Panel header="Specific Topical Term">
    //               <RefinementList attribute="specificTopicalTerm" />
    //             </Panel>
    //             <Panel header="Format">
    //               <RefinementList attribute="format" />
    //             </Panel>
    //           </DynamicWidgets>
    //         </div>

    //         <div className="search-panel__results">
    //           <SearchBox placeholder="Search over 480,000 ESTC records... try 'Paradise Lost Milton' or '1674'" className="searchbox" />
    //           <Hits hitComponent={Hit} />

    //           <div className="pagination">
    //             <Pagination />
    //           </div>
    //         </div>
    //       </div>
    //     </InstantSearch>
    //   </div>
    // </div>
  );
}

type HitProps = {
  hit: Hit;
};


function Hit({ hit }: HitProps) {
  const navigate = useNavigate();

  const handleClick = () => {
    // console.log(hit.objectID);
    navigate(`/record/${hit.objectID}`, { state: { hit } });
    // window.open(`/record/${hit.objectID}`,'_blank', 'rel=noopener noreferrer');

    // window.open(`/record/${hit.objectID}`, "_blank", 'noopener,noreferrer');
  };


  hit.date = String(hit.date);
  hit.subtitle = String(hit.subtitle);
  console.log(hit.objectID);

  return (
    <article>
      <h3>
        <Highlight attribute="title" hit={hit} />
      </h3>
      <p>
        { hit.author.trim().length === 0 ?  `` : <strong>Author: </strong> }
        <Highlight attribute="author" hit={hit} />
      </p>
      <p>
        { hit.imprintOriginal.trim().length === 0 ?  `` : <strong>Imprint: </strong> }
        <Highlight attribute="imprintOriginal" hit={hit} />
      </p>
      <p>
        { hit.printerAutoExtracted.trim().length === 0 ?  `` : <strong>Printer: </strong> }
        <Highlight attribute="printerAutoExtracted" hit={hit}/>
      </p>
      <p>        
        { hit.generalNote.trim().length === 0  ?  `` : <strong>General Note: </strong> }
        <Highlight attribute="generalNote" hit={hit}/>
      </p>
      <p>
        { hit.estcNo.trim().length === 0 ?  `` : <strong>ESTC No: </strong> }
        <Highlight attribute="estcNo" hit={hit} />
      </p>
      <p>
        { hit.eeboURL.trim().length === 0 ?  `` : <strong>EEBO: </strong> }
        <a href={hit.eeboURL} target='_blank'>{hit.eeboURL}</a>
      </p>
      { hit.archiveURL.trim().length === 0 ? null : (
        <p>
          <strong>Internet Archive: </strong>
          <a href={hit.archiveURL} target='_blank'>View on Internet Archive</a>
        </p>
      )}
      <p>        
        { hit.yearAutoExtracted.toString().trim().length === 0 ?  `` : <strong>Year: </strong> }
        <Highlight attribute="yearAutoExtracted" hit={hit} />
      </p>
      <button onClick={handleClick}>View Record</button>
      {/* <a href={`/record/${hit.objectID}`} target='_blank'>View Record</a> */}
    </article>
  );
}
